(function() {
    'use strict';

    var $carousel = $('#myCarousel');

    // Move to the second slide after 4 seconds
    var carouselTimeout = setTimeout(function() {
        $carousel.carousel(1);
    }, 4000);

    // Clear the timeout if the user clicks on any of the carousel's indicators
    $carousel.find('.carousel-indicators li').on('click', function(e) {
        clearTimeout(carouselTimeout);
    });

    var marketSectorsInterval = null;

    // Check if the user lands directly into the market sectors' slide
    if ($carousel.find('[data-slide-to="1"]').hasClass('active')) {
        cycleThroughMarketSectors($carousel.find('.carousel-inner .active')[0]);
    }

    $carousel.on('slide.bs.carousel', function(e) {
        cycleThroughMarketSectors(e.relatedTarget);
    });

    function cycleThroughMarketSectors(target) {
        // Clear the interval when changing slide
        clearInterval(marketSectorsInterval);

        var $target = $(target);

        if (target.id !== 'home-ms-slide') return;

        // Cycle through list items
        marketSectorsInterval = setInterval(function() {
            var $next = $target.find('ul li.active').next();

            if ($next.length === 0) {
                $next = $target.find('ul li:first-child');
            }

            $next.find('a').click();
        }, 3000);

        // Clear the interval when clicking on a list item
        $target.find('ul li a').on('mousedown', function(e) {
            clearInterval(marketSectorsInterval);
        });
    }
    jQuery('#news-carousel .item:first').addClass('active');
    

})();
function bsCarouselAnimHeight() {
    $('#news-carousel').on('slide.bs.carousel', function (e) {
        var nextH = $(e.relatedTarget).height();
        $(this).find('.active.item').parent().animate({
            height: nextH
        }, 500);
    });
}

bsCarouselAnimHeight();

if ($('#news-carousel').length > 0) {

    var inview = new Waypoint.Inview({
    element: $('#news-carousel')[0],
    enter: function(direction) {
        $('#news-carousel').carousel({
        interval: 7000
        })
    },
    exited: function(direction) {
        $('#news-carousel').carousel('pause');
    }
    })

}

$(document).ready(function(){
    var articleDate = $('.cs-date').data('date');
    var currentDate = $('.current-time').data('date');
    var maxDiff = (2 * 24 * 60 * 60);
    var diff = currentDate - articleDate;
    if(diff < maxDiff){
        $('#myCarousel .new-label').removeClass('hide');
    }
});
