(function() {
    'use strict';
    
    var scroll = require('../components/scroll');

    $('.scroll-me').on('click', function(e) {
        e.preventDefault();

        var anchor = $(this).attr('href');

        scroll.to(anchor, -80);
    });

})();