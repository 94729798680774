(function() {
    'use strict';


    $(document).bind('ajaxStart', function(){
        $('.spinner').fadeIn();
    }).bind('ajaxStop', function(){
        $('.spinner').fadeOut();
    });

    function loadTechnicalLibraryTables(id, url){

        if($('#'+id).length > 0){
            $('#'+id).remove();
            $(window).trigger('content.changed');
        } else{
             var element = $('<div class="document-item" id="' + id + '" class="active">').load(url);
             $('#document-container-wrapper').prepend(element);
             setTimeout(function(){
                 $(window).trigger('content.changed');   
             },1000);
                 
        }
    }

    $('#toggle-ds').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-ds',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-sd').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-sd',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-msds').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-msds',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-cert').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-cert',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-brochures').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-brochures',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-policies').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-policies',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-doc').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-doc',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-nbs').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-nbs',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-bim').on('click', function(event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-bim',url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#toggle-installation-guides').on('click', function (event) {
        var url = $(this).data('url');
        loadTechnicalLibraryTables('container-installation-guides', url);
        event.preventDefault();
        $(this).toggleClass('active');
    });

    $('#show-all').on('click', function(event) {
        $('.document-item').remove();
        $('#dl-filters .filter').removeClass('active').click();
    });


})();