(function() {
    'use strict';

     // header search - uses easy-autocomplete
    // http://easyautocomplete.com/
    // require('easy-autocomplete');
    // var autocomplete_options = {

    //   url: "/search/main-search-json",

    //   getValue: "title",

    //   list: {   
    //     match: {
    //       enabled: true
    //     }
    //   },

    //   theme: "square"
    // };

    // $(".keywords_to_find").easyAutocomplete(autocomplete_options);
    

// end of header search

    
    if ($('#header-search').length === 0) return;

    

    var Scroll = require('../components/scroll');

    jumpToSection(window.location.hash);



// selectize - technical library predictive

    require('selectize');

    var $search = $('#header-search select');

    $search.selectize({
        valueField: 'url',
        labelField: 'name',
        searchField: 'name',
        load: function(query, callback) {
            if (!query.length) return callback();

            $.ajax({
               url: '/search/main-search-json' ,
               type: 'GET'
            }).success(function(data) {
                var results = transformSearchResults(data);

                callback(results);
                console.log(query);
                $('.keywords_to_find').val(query);

            }).error(function() {
                console.log('ERROR');
            });
        }
    });

    $search[0].selectize.on('change', function(url) {
        if (!url) return;

        window.open(url, 'blank');
    });

// end 

    function transformSearchResults(data) {
        var results = $.map(data, function(item) {
            return {
                id: item.entry_id,
                name: item.title,
                url: getFileUrl(item)
            };
        });

        return results;
    }

    function getFileUrl(item, channel_id) {
        //staff
        if (item.channel_id == 17) {
            return "/about-us/staff#staff" + item.entry_id;
        }
        else if (item.channel_id == 2) {
            return "/case-study/";
        }
        else if (item.channel_id == 3) {
            if (item.pdf_download == null) {
                
                return "/news/" + item.url_title;
            }
            else {
                return item.pdf_download;
            }
        }
        else if (item.channel_id == 27) {
            return "/about-us/environmental";
        }

        else if (item.channel_id == 28) {
            return "/technical-library/#policies";
        }

        else if (item.channel_id == 1) {
            return "/products/" + item.url_title;
        }
        
        else if (item.channel_id == 33) {
	        // this is the keyword search channel.
            return item.destination_url;
        }


        
        else if (item.channel_id == 18) {
            //market sectors..
            if (item.entry_id >= 62 && item.entry_id <= 68) {
                // this is hard coded to the top level market sectors 
                return "/market-sectors/" + item.url_title;
            } else if (item.entry_id == 69) {
                return "/products/correx";
            } else if (item.entry_id == 70 || item.entry_id == 71 || item.entry_id == 72) {    
                return "/services/" + item.url_title;
            }
        }


    }



    function jumpToSection(hash) {
        Scroll.to(hash, -100);
    }
})();