(function() {
    'use strict';
    
    if ($('#dl-search').length === 0) return;

    var Scroll = require('../components/scroll');

    jumpToSection(window.location.hash);

    require('selectize');

    var $search = $('#dl-search select');

    $search.selectize({
        valueField: 'url',
        labelField: 'name',
        searchField: 'name',
        load: function(query, callback) {
            if (!query.length) return callback();

            $.ajax({
               url: '/technical-library/search-json' ,
               type: 'GET'
            }).success(function(data) {
                var results = transformSearchResults(data);

                callback(results);
            });
        }
    });

    $search[0].selectize.on('change', function(url) {
        
        if (!url) return;
        
        if(!window.open(url)){
            window.location = url
        }

        //window.location = url
    });

    function transformSearchResults(data) {
        var results = $.map(data, function(item) {
            return {
                id: item.entry_id,
                name: item.title,
                url: getFileUrl(item)
            };
        });

        return results;
    }

    function getFileUrl(item) {
        return item.ds_downloadable_file 
            || item.sd_downloadable_file 
            || item.ca_document 
            || item.brochures_downloadable_file
            || item.policy_pdf_download;
    }

    function jumpToSection(hash) {
        Scroll.to(hash, -100);
    }
})();