(function() {
    'use strict';
/* CORREX CALCULATOR */
    
    var domain = 'https://cordek.com';


    /* handling form submission */
    $('.calculate-quote').on('click',function(e){
        e.preventDefault();
        submitcalculation();
    });

    $('#correx-calculator-form').on('submit',function(e){
	    e.preventDefault();
	    console.log('stopped submit');
        submitcalculation();
    });
    
    
    

    /* end of handling form submission */

    /* handling changing dropdown values */

     $('#typeofcorrex').on('change',function(){
        clearSelect('#size_of_sheet','-- CHOOSE TYPE --');// clears the other inputs
        clearSelect('#sheetcolour','-- CHOOSE SIZE --');// clears the other inputs
        CorrexFormSize($('#typeofcorrex').val());
       
    });

     $('#size_of_sheet').on('change',function(){
        CorrexFormColour($('#typeofcorrex').val(),$('#size_of_sheet').val());
    });
    
    
    $('#sheetcolour').on('change',function(){
	    
        submitcalculation();
    });

     /* end of handling changing dropdown values */

    function clearSelect(selectID,blankEntry) {
        $(selectID).empty().append('<option value="">'+blankEntry+'</option>');

    } 
    function CorrexFormSize(typeOfSheet) {
        var URLtoCall = domain + "/products/correx-calculator/" + typeOfSheet;
        //console.log(URLtoCall);
        $.get(URLtoCall, function(data, status){
            // returns all of the correx calculator data
            var dataJSON = JSON.parse(data)
            var sizeValues = getUniqueValues('size_of_sheet',dataJSON).sort();
            //console.log(sizeValues);
            addtoSelect('size_of_sheet',sizeValues);
        });
    }

    function CorrexFormColour(typeOfSheet,sheetSize) {
        var URLtoCall = domain + "/products/correx-calculator/" + typeOfSheet +'/'+sheetSize;
        console.log(URLtoCall);
        $.get(URLtoCall, function(data, status){
            // returns all of the correx calculator data

           var dataJSON = JSON.parse(data)
           var sizeValues = getUniqueValues('size_of_sheet',dataJSON).sort();
           var colourValues = getUniqueValues('colour',dataJSON).sort();
           // console.log(URLtoCall);
           // console.log(sizeValues);
           // console.log(colourValues);
           addtoSelect('sheetcolour',colourValues);

        });
    }

    function addtoSelect(selectID,selectValues){

            $('#'+selectID).empty().append().append('<option value="">-- PICK ONE --</option>');
            //clears out the dropdown

            $.each(selectValues, function(key, value) {   
                $('#'+selectID)
                    .append($('<option>', { key : key })
                    .text(value)); 
            });
    }

    function getUniqueValues(property, anArray){
        var values = [];
       $.each(anArray,function(i,e){
            if(values.indexOf(e[property]) === -1){
                values.push(e[property]);
            }
       });
       return values;   
    }
   

   function confirmPrice(price){
   		if (price>0){
	    	$('.costofcorrex').html('&pound;'+price+'<span>+VAT</span>');
	    	$('#pricetopay').val(price);
	    	$('.getCorrexQuote').show();
		} else {
			$('.costofcorrex').html('<span class="increaseQuantity">Please increase your quantity - a minimum order of 50 sheets applies...</span>');
			$('.getCorrexQuote').hide();
		}
   }


    function submitcalculation() {
        var typeofcorrex = $('#typeofcorrex').val();
        var size_of_sheet = $('#size_of_sheet').val();
        var quantity = $('#quantityofsheets').val();
        var colour = $('#sheetcolour').val();
        
        var theURLtoCall = domain + "/products/correx-calculator/"+typeofcorrex+"/"+size_of_sheet+"/"+colour;
        console.log(theURLtoCall);

         $.get(theURLtoCall, function(data, status){
            var dataJSON = JSON.parse(data)
            var palletQuantity = dataJSON[0]['pallet_quantity'];
            var price_each_50_100 = dataJSON[0]['price_each_50_100'];
            var price_each_less_than_1_pallet = dataJSON[0]['price_each_less_than_1_pallet'];
            var price_each_1_pallet = dataJSON[0]['price_each_1_pallet'];
            var price_each_3_pallet = dataJSON[0]['price_each_3_pallet'];
            var pricetopay = 0;
			
			var palletRatio = quantity/palletQuantity;
			
			console.log(typeofcorrex + ' ' + palletRatio);
			
			if (typeofcorrex == 'Anti-Static' && palletRatio <1) {
				$('.costofcorrex').html('<span class="increaseQuantity">Please increase your quantity - minimum order quantity is ' +palletQuantity +' sheets</span>');

			} else {
			
				if (quantity < 50){
					$('.costofcorrex').html('<span class="increaseQuantity">Please increase your quantity - a minimum order of 50 sheets applies...</span>');
					$('.getCorrexQuote').hide();
					
				} else if (quantity > 49 && quantity<=100){
	                pricetopay = price_each_50_100 * quantity;
	                confirmPrice(pricetopay.toFixed(2));
	                $('.getCorrexQuote').show();
	            } else {
	                
	                if (palletRatio<1){
	                    pricetopay = price_each_less_than_1_pallet * quantity;
	                    confirmPrice(pricetopay.toFixed(2));
	                }
	                if (palletRatio >= 1 && palletRatio < 3){
	                    pricetopay = price_each_1_pallet * quantity;
	                    confirmPrice(pricetopay.toFixed(2));
	                }
	                if (palletRatio >= 3){
	                    pricetopay = price_each_3_pallet * quantity;
	                    confirmPrice(pricetopay.toFixed(2));
	                }
	                $('.getCorrexQuote').show();
	            }
	        }    
         //submitEventToGoogle(typeofcorrex,size_of_sheet,quantity,colour,pricetopay);
		var quoteDetails = "Type: " + typeofcorrex + " Size: " + size_of_sheet + " Quantity:" + quantity  + " Colour:" + colour + " Price Quoted:" + pricetopay;
		ga('send', 'event', 'Correx', 'Quote', quoteDetails);
		
        });
        
        
    }
    
     $('.cta-pop-up-internal').on('click',function(e){
        e.preventDefault();
        var typeofcorrex = $('#typeofcorrex').val();
        var size_of_sheet = $('#size_of_sheet').val();
        var quantity = $('#quantityofsheets').val();
        var colour = $('#sheetcolour').val();
        var pricetopay = $('#pricetopay').val();
        
        var linktoload = domain + '/contact/correx-form/' + typeofcorrex + '/' + size_of_sheet  + '/' + colour  + '/' + quantity  + '/' + pricetopay;
		//console.log(linktoload);
		
				
        var nameofmodal = 'Correx Quote';
        $('.cta-iframe').attr('src',linktoload);
        $('#myModalLabel').html(nameofmodal);
        $('#myModal').modal('show');
        
        
        
    });
    
    function submitEventToGoogle(typeofcorrex,size_of_sheet,quantity,colour,pricetopay){
	    
	    var quoteDetails = "Type: " + typeofcorrex + " Size: " + size_of_sheet + " Quantity:" + quantity  + " Colour:" + colour + " Price Quoted:" + pricetopay;
	    
	    console.log(quoteDetails);
	    
	    //actionType = Quote or send
		ga('send', 'event', 'Correx', 'Quote', quoteDetails);
	    
	    
    }
    
    
/*
     $('.cta-pop-up').on('click',function(e){
        e.preventDefault();
        var linktoload = $(this).attr('href');
        var nameofmodal = $(this).data('linkword');
        $('.cta-iframe').attr('src',linktoload);
        $('#myModalLabel').html(nameofmodal);
        $('#myModal').modal('show');
    });
*/
    
    
   })();