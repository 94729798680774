(function() {
    'use strict';
    
    var scroll = require('../components/scroll');

    $('.scroll-me').on('click', function(e) {
        e.preventDefault();

        var anchor = $(this).attr('href');

        scroll.to(anchor, -80);
    });

    $('.product-suitable .table-wrap table').addClass('table table-responsive data-table');

    require('easyzoom');

    //$('.easyzoom').easyZoom();

    $('.cta-pop-up').on('click',function(e){
        e.preventDefault();
        var linktoload = $(this).attr('href');
        var nameofmodal = $(this).data('linkword');
        var iframeHeight = $(this).data('height');
        $('.cta-iframe').attr('src',linktoload);
        $('#myModalLabel').html(nameofmodal);
        $('#myModal').modal('show');
    });
    
    $('.cta-slide-down').on('click',function(e){
        e.preventDefault();
        
        var whereto = $(this).attr('href');
        var topCoord = $(whereto).offset();
        var scrollingTo = topCoord.top - 200;
        window.scrollTo(0,scrollingTo);
// 		console.log(scrollingTo);
    });

    $('#additonal-products h2').click(function () {
        $("#additonal-products ul").toggleClass("active");
    });

    


})();