(function () {
    'use strict';


    if ($('#rst-map').length) {
        require('gmaps');

        require('../modules/typeahead.bundle');

        var Bloodhound = require('../modules/bloodhound');

        var SalesMapData = require('./sales-map-data');

        var defaultOptions = {
            latitude: 54.838,
            longitude: -3.327,
            zoom: 5
        }


        var map = new GMaps({
            el: '#rst-map',
            lat: defaultOptions.latitude,
            lng: defaultOptions.longitude,
            zoom: defaultOptions.zoom
        });


        var engine = new Bloodhound({
            local: SalesMapData.agentsAsKeyValue(),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('code')
        });

        var $searchInput = $('.search-form input[name="postcode"]');
        var $searchButton = $('.search-form button');
        var previousMarker = null;
        var agentsAdded = false;
        var currentAgent = null;
        var marker = null;

        $searchInput.attr('pattern', "[A-Za-z]{1,2}[0-9][0-9A-Za-z]?")
        $searchInput.attr('required', true)
        $searchInput.attr('title', "Please enter the first part of your postcode, eg: NW14")

        $searchInput.typeahead({
            minLength: 2
        }, {
            name: 'agents',
            source: search
        }).bind('typeahead:render', function (e, suggestion) {
            // Reset the map view (i.e. show the initial view with the UK and Ireland focused)
            // if no suggestions are found
            if (!suggestion) {
                if ($searchInput.val().length < 2) {
                    resetMapView();
                }

                return;
            }


            currentAgent = SalesMapData.agents[suggestion.index];
        });

        $searchInput.on('keyup', function (e) {
            if ($searchInput.val().length === 0) {
                resetMapView();
            }
        });

        $searchInput.on('keydown', function (e) {
            // Enter key

            if (e.keyCode === 13) {
                if (!$searchInput[0].reportValidity()) {
                    // swal('Please enter a complete postcode');
                    return;
                }
                if ($searchInput.val().length < 2) {

                    setTimeout(function () {
                        swal('Please enter a complete postcode');
                    }, 1);


                } else {
                    searchPostcode($(this).val());
                }

            }
        });

        $searchButton.on('click', function (e) {
            if (!$searchInput[0].reportValidity()) {
                // swal('Please enter a complete postcode');
                return;
            }
            if ($searchInput.val().length < 2) {
                swal('Please enter a complete postcode');
            } else {
                searchPostcode($searchInput.val());
            }
        });

        function search(query, sync) {
            engine.search(query, sync);
        }

        function resetMapView() {
            map.setZoom(defaultOptions.zoom);
            map.panTo(new google.maps.LatLng(defaultOptions.latitude, defaultOptions.longitude));

            if (marker) {
                marker.setMap(null);
                currentAgent = null;
            }
        }

        function addMarker(data, overrides) {
            $.extend(data, overrides);
            return map.addMarker({
                lat: data.latitude,
                lng: data.longitude,
                title: data.name,
                icon: '/images/map-marker.png',
                infoWindow: {
                    content:
                        '<div class="agent">' +
                        '<h3>' + data.name + '</h3>' +
                        '<img class="img img-responsive" src="' + data.photo + '">' +
                        '<p><sm>Technical Advisor covering this postcode</sm></p>' +
                        '<a class="phone" href="tel:' + data.phone.replace(' ', '') + '">' + data.phone + '</a>' +
                        '<a class="email" href="mailto:' + data.email + '?subject=Sales Message From Cordek Website" onclick="ga(\'send\', \'event\', \'Sales-Section\', \'Email\', \'' + data.name + '\',1);">' + data.email + '</a>' +

                        '</div>'
                }
            });
        }

        function addAgentsToTheMap() {
            $.each(SalesMapData.agents, function (index, agent) {
                var marker = addMarker(agent);

                SalesMapData.agents[index].marker = marker;
            });

            agentsAdded = true;
        }

        function searchPostcode(postcode) {
            ga('send', 'event', 'Postcode search', 'Submit', postcode, 1);
            currentAgent = null;
            //if no results, strip numbers from postcode, trim length to 2 and try again.
            // dont infinite loop.


            var updateMarker = function (postcode) {
                // address: postcode + ', United Kingdom, Europe',
                GMaps.geocode({
                    address: postcode + ', UK',
                    callback: function (results, status) {
                        if (status !== 'OK') {
                            swal('Postcode not found');
                            return;
                        }

                        var coordinates = results[0].geometry.location;

                        if (currentAgent) {
                            if (marker) {
                                marker.setMap(null);
                            }


                            marker = addMarker(currentAgent, {
                                latitude: coordinates.lat(),
                                longitude: coordinates.lng()
                            });

                            map.setZoom(10);
                            map.panTo(marker.position);

                            // Show the marker's details window
                            google.maps.event.trigger(marker, 'click', {
                                latLng: new google.maps.LatLng(0, 0)
                            });
                        }
                    }
                });
            }
            var performEngineSearch = function (originalPostcode, postcode, tries) {
                if (tries > 1) {
                    //Too many tries - prevent infinite loop
                    return;
                }
                //Perform search with full postcode
                engine.search(postcode, function (results) {
                    if (results.length == 0) {
                        //Search was unsuccessful, recurse and retry once with only the postal area (2 characters)
                        performEngineSearch(originalPostcode, postcode.substr(0, 2), tries + 1);
                    } else {
                        //Search was successful, update the marker
                        currentAgent = SalesMapData.agents[results[0].index];
                        updateMarker(originalPostcode);
                    }


                });
            }
            performEngineSearch(postcode, postcode, 0);
        }
    }
})();