// the first paul hood was Shaun Frost previously
var SalesMapData = {

    agents: [
	    {
            name: 'Adam Scaldwell',
            phone: '07967 746046',
            email: 'ascaldwell@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_adam_scaldwell_cordek_1808.jpg',
            latitude: 51.534,
            longitude: -0.109,
            postcodes:['SE1','SE16']
        },
        {
            name: 'Charles Sutherland',
            phone: '07967 746045',
            email: 'csutherland@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_charles_sutherland_cordek_18795.jpg',
            latitude: 51.305,
            longitude: -0.607,
            postcodes:['PO','TW','UB','SL','GU','CR','KT','SM','RH1','RH2','RH3','RH4','RH5','RH12', 'RH13','RH14','RH20']
        },
        {
            name: 'Simon Roberts',
            phone: '07717 666649',
            email: 'sroberts@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_simon_roberts_cordek_18902.jpg',
            latitude: 52.073,
            longitude: -0.663,
            postcodes: ['AL','B1','B2','B3','B4','B5','B6','B7','B8','B9','B10','B11','B12','B13','B14','B15','B16','B17','B18','B19','B20','B21','B23','B24','B25','B26','B27','B28','B29','B30','B31','B32','B33','B34','B35','B36','B37','B38','B40','B42','B43','B44','B45','B46','B47','B48','B49','B50','B60','B61','B62','B63','B64','B65','B66','B67','B68','B69','B70','B71','B72','B73','B74','B75','B76','B77','B78','B79','B80','B90','B91','B92','B93','B94','B95','B96','B97','B98','B99','CV','DE','EN','HA','HP','LU','MK','N3','N9','NN','N11','N12','N13','N14','N18','N20','N21','NW7','PE1','PE2','PE3','PE4','PE5','PE6','PE7','PE8','PE9','PE10','PE11','PE12','PE13','PE14','PE15','PE16','PE19','PE26','PE27','PE28','PE29','WD','N3','N9','N11','N12','N13','N14','N18','N20','N21','NW7','HA','CV','LE','LN','NG','WS','ST']
        },
        
        {
            name: 'James Provan',
            phone: '07748 775838',
            email: 'jprovan@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_james_provan_cordek_18975.jpg',
            latitude: 55.867,
            longitude: -3.735,
            postcodes: ['FK','TS','DL','DH','SR','CA','DG','KY','KW','AB','IV','EH','TD','KA','ML','G','PA','PH','DD','NE','WA','CH','HU','WN','BL','PR','BB','FY','LA','SK','OL','M','L','LL','CW']
        },
        {
            name: 'James Poulter',
            phone: '07748 775842',
            email: 'jpoulter@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_james_poulter_cordek_18844.jpg',
            latitude: 51.400,
            longitude: -1.899,
            postcodes: ['BS','BA','RG','TR','EX','TQ','PL','BH','DT','GL','NP','SO','HR','OX','LD','TF','SY','TA','CF','SA','DY','WV','SN','SP','WR']
        },
        
        {
            name: 'Charles Sutherland',
            phone: '07967 746045',
            email: 'csutherland@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_charles_sutherland_cordek_18795.jpg',
            latitude: 53.427,
            longitude: -7.358,
            postcodes: ['BN','PO','TW','UB','SL','GU','CR','KT','SM','RH1','RH2','RH3','RH4','RH5','RH12','RH13','RH14','RH20'] 
        },
        {
            name: 'Paul Hood',
            phone: '07384 468780',
            email: 'info@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_paul_hood_cordek.jpg',
            latitude: 53.427,
            longitude: -7.358,
            postcodes: ['CB','CB1','CB2','CB3','CB4','CB5','CB6','CB7','CB8','CB9','CB10','CB11','CB21','CB22','CB23','CB24','CB25','PE','PE20','PE21','PE22','PE23','PE24','PE25','PE30','PE31','PE32','PE33','PE34','PE35','PE36','PE37','PE38','IG','CM','RM','SS','CO','SG','DA','NR', 'IP']
        },
        {
            name: 'Royston Lorriman',
            phone: '07384 460795',
            email: 'info@cordek.com',
            photo: '/uploads/staff/_small-thumb/employee_royston_lorriman_cordek.jpg',
            latitude: 53.427,
            longitude: -7.358,
            postcodes: ['BN','RH6','RH7','RH8','RH9','RH10','RH11','RH15','RH16','RH17','RH18','RH19','TN','BR','ME','CT','SE2',  
'SE1','SE2','SE3','SE4','SE5','SE6','SE7','SE8','SE9','SE10','SE11','SE12','SE13','SE14','SE15','SE17','SE18','SE19','SE20','SE21','SE22','SE23','SE24','SE25','SE26','SE27','SE28','SW1','SW2','SW3','SW4','SW5','SW6','SW7','SW8','SW9','SW10','SW11','SW12','SW13','SW14','SW15','SW16','SW17','SW18','SW19','SW20','W1','W2','W3','W4','W5','W6','W7','W8','W9','W10','W11','W12','W13','W14','E1','E2','E3','E4','E5','E6','E7','E8','E9','E10','E11','E12','E13','E14','E15','E16','E17','E18','E20','EC1','EC2','EC3','EC4','WC1','WC2','N1','N2','N4','N5','N6','N7','N8','N10','N15','N16','N17','N19','N22','NW1','NW2','NW3','NW4','NW5','NW6','NW8','NW9','NW10','NW11']
        }
        ,
    ],

    agentsAsKeyValue: $.proxy(function () {
        var values = [];

        $.each(this.agents, function (agentIndex, agent) {
            $.each(agent.postcodes, function (postcodeIndex, postcode) {
                values.push({
                    index: agentIndex,
                    code: postcode,
                    agent: agent.name
                });
            })
        });

        return values;
    }, SalesMapData)

};

module.exports = SalesMapData;