var Scroll = {

    to: function(selector, offset, speed, animation, target) {
        offset = offset || 0;
        speed = speed || 900; // Sane default for smooth scrolling
        animation = animation || 'swing';
        target = target || 'html, body';

        var $selector = $(selector);

        // If the element is not found, we try to find an anchor by its name
        if ($selector.length === 0) {
            $selector = $('a[name="' + selector.substring(1, selector.length) + '"]');

            // If it's still not found, we silently ignore the scrolling action
            if ($selector.length === 0) {
                return false;
            }
        }

        $(target).stop().animate({
            scrollTop: $selector.offset().top + offset
        }, speed, animation);
    }
};

module.exports = Scroll;