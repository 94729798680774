var $ = (typeof window !== "undefined" ? window.$ : typeof global !== "undefined" ? global.$ : null);
var moment = require('moment');

require('bootstrap');
window.swal = require('bootstrap-sweetalert');
require('sticky-kit');
require('./modules/contact-form-validation');
require('./components/sales-map');
require('./components/owl.carousel.min');
require('./components/jquery.waypoints.min');
require('./components/waypoints.inview');
require('./pages/bim-library');
//require('./modules/jquery.smoothTouchScroll.min');
//$("#serv-banner").smoothTouchScroll();
var formopen = 0;
(function () {
    'use strict';
    // Happy coding!
    /*   $(".contact-form").hide();
     $("#opencontactform").click(function() {
     if($(".contact-form").hasClass('show')){
     $(".contact-form").hide(500).removeClass('show');
     } else{
     $(".contact-form").show(500).addClass('show');
     }
     });*/
    // Happy coding!
    var sync1 = $("#sync1");
    var sync2 = $("#sync2");
    sync1.owlCarousel({
        singleItem: true,
        slideSpeed: 1000,
        navigation: true,
        pagination: false,
        afterAction: syncPosition,
        responsiveRefreshRate: 200,
    });
    sync2.owlCarousel({
        items: 5,
        itemsDesktop: [1200, 5],
        itemsDesktopSmall: [992, 5],
        itemsTablet: [768, 5],
        itemsMobile: [420, 3],
        pagination: false,
        responsiveRefreshRate: 100,
        afterInit: function (el) {
            el.find(".owl-item").eq(0).addClass("synced");
        }
    });
    function syncPosition(el) {
        var current = this.currentItem;
        $("#sync2")
            .find(".owl-item")
            .removeClass("synced")
            .eq(current)
            .addClass("synced")
        if ($("#sync2").data("owlCarousel") !== undefined) {
            center(current)
        }
    }   

    $("#sync2").on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).data("owlItem");
        sync1.trigger("owl.goTo", number);
    });
    function center(number) {
        var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
        var num = number;
        var found = false;
        for (var i in sync2visible) {
            if (num === sync2visible[i]) {
                var found = true;
            }
        }
        if (found === false) {
            if (num > sync2visible[sync2visible.length - 1]) {
                sync2.trigger("owl.goTo", num - sync2visible.length + 2)
            } else {
                if (num - 1 === -1) {
                    num = 0;
                }
                sync2.trigger("owl.goTo", num);
            }
        } else if (num === sync2visible[sync2visible.length - 1]) {
            sync2.trigger("owl.goTo", sync2visible[1])
        } else if (num === sync2visible[0]) {
            sync2.trigger("owl.goTo", num - 1)
        }
    }

    $('.btn-enquiry').click(function () {
        var relatedproducts = $('.related-products').position().top;
        $('html, body').animate({scrollTop: relatedproducts}, 800);
        return false;
    });
    $(".contact-form").hide();
    $("#opencontactform").click(function () {
        if ($(".contact-form").hasClass('show')) {
            $(".contact-form").hide(500).removeClass('show');
        } else {
            $(".contact-form").show(500).addClass('show');
        }
    });
    $(".product-thumb").click(function () {
        var currHero = $('.product-hero').attr('src');
        var changeTo = $(this).attr('src');
        $('.product-hero').attr('src', $(this).attr('src'));
        $(this).attr('src', currHero);
    });

    $(".sidebar-container").stick_in_parent({
        offset_top: 100,
        parent: '#page',
        bottoming: true,
    });
    var bodyElement = $('body');

    function sidebarResize() {
        var bodyWidth = bodyElement.innerWidth();
        if (bodyWidth > 768) {
            $('#sidebar').css({minHeight: $('#body-area').height()});
        } else {
            $('#sidebar').css({minHeight: 0});
        }
    }
    $(window).on('content.changed',function(){
        sidebarResize();
    });
    $(window).resize(function () {
        sidebarResize();
    });
    $('#refine-downloads').click(function (e) {
        $('.read-more').show();
        $('.read-more-button').hide();
        $('.prod-row').fadeOut();
        $('.bim-item').fadeOut();
        console.log(this.name);
        $('.refine-list input:checked').each(function () {
            $("." + this.name).fadeIn();
            //alert(this.name);
        });
    });

    sidebarResize();
    require('./pages/home');
    require('./pages/products');
    require('./pages/correx-calculator');
    require('./pages/contact');
    require('./pages/services');
    require('./pages/about-us');
    require('./pages/technical-library');
    require('./pages/technical-library2');
})();
require('./pages/header');
$('.dropdown').click(function () {
    $(this).find('.dropdown-menu').first().stop(true, true).slideToggle(200);
});
$('.dropdown-menu li').on('click', function (e) {
    $('.dropdown-menu li').removeClass('active');
});
$('.read-more-reveal').on('click', function (e) {
    var scrollMe = require('./components/scroll');
    var scrolllocation = $(this).attr('scroll-to')
    scrollMe.to('#' + scrolllocation, -80);

    $("." + $(this).data("section")).slideToggle();
    if ($(this).html().indexOf('Show') > -1) {
        $(this).html($(this).html().replace('Show', 'hide'));
    } else {
        $(this).html($(this).html().replace('hide', 'Show'));
    }
});
$('.cform-link').click(function () {
    $(".panel-link").removeClass("active");
    $(".tab-pane").removeClass("active in");
    $("#cform-panel-link").addClass("active");
    $("#cform").addClass("active in");
});
if (window.location.hash) {
    var hash = window.location.hash;
    var element = $('.panel-link a[href="' + hash + '"],#cform-panel-link a[href="' + hash + '"]');
    element.click();
    $('html, body').animate({
        scrollTop: element.parent().offset().top
    }, 2000);
}    