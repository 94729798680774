(function() {
    'use strict';

    if (window.gallery == undefined) return;

    var images = getImagesByType();
    var mainContainer = '#masonry';

    var squareLength = images.square.length;

    for (var i = 0; i < squareLength; i++) {
        var image = null;
        var squarePair = null;
        var $groupWrapper = $('<div class="group-wrapper">');

        if (images.square.length > 0) {
            if (i % 2 == 0) {
                image = getSquare();

                $groupWrapper.append(image);
            } else {
                if (images.square.length >= 2) {
                    var portrait = (images.portrait.length > 0);
                    var landscape = (images.landscape.length > 0);
                    var needsSquares = false;

                    if (portrait && landscape) {
                        image = Math.random() > 0.5 ? getPortrait() : getLandscape();
                        needsSquares = true;
                    } else if (portrait) {
                        image = getPortrait();
                        needsSquares = true;
                    } else if (landscape) {
                        image = getLandscape();
                        needsSquares = true;
                    } else {
                        image = getSquare();
                    }

                    if (needsSquares) {
                        squarePair = getSquarePair();
                    }

                    $groupWrapper.append(image);
                    $groupWrapper.append(squarePair);
                } else {
                    image = getSquare();
                    $groupWrapper.append(image);
                }
            }

            $groupWrapper.appendTo(mainContainer);
        } else {
            return;
        }
    }

    function getSquare() {
        return getImageBlock(images.square.pop(), 'mas-large-square');
    }

    function getSquarePair() {
        return getImageBlock(images.square.pop()).add(getImageBlock(images.square.pop()));
    }

    function getLandscape() {
        return getImageBlock(images.landscape.pop());
    }

    function getPortrait() {
        return getImageBlock(images.portrait.pop());
    }

    function getImageBlock(image, classOverride) {
        var className = classOverride || image.className;
        var $block = $('<div class="image-block">');
        var $image = $('<img>').attr('src', image.path);

        $block.addClass(className);

        $block.append($image);

        if (image.description || image.url) {
            var $overlay = $('<div class="overlay">');

            if (image.description) {
                var $description = $('<p>').text(image.description);

                $overlay.append($description);
            }

            if (image.url) {
                var $url = $('<a>').attr('href', image.url);

                $overlay.append($url);
            }

            $block.append($overlay);
        }

        return $block;
    }

    function getOrientation(orientation) {
        switch (orientation) {
            case 'Square':
                return {
                    className: 'mas-small-square',
                    directory: '_square'
                };
            case 'Landscape':
                return {
                    className: 'mas-landscape',
                    directory: '_landscape'
                };
            case 'Portrait':
                return {
                    className: 'mas-portrait',
                    directory: '_portrait'
                };
        }
    }

    function getTransformedImagePath(path, directory) {
        return path.replace('market_sectors_galleries', 'market_sectors_galleries/' + directory);
    }

    function getImagesByType() {
        var images = {
            square: [],
            landscape: [],
            portrait: []
        };

        $.each(gallery, function(index, item) {
            var orientation = getOrientation(item.orientation);
            var path = getTransformedImagePath(item.image, orientation.directory);
            var image = {
                description: item.description,
                url: item.link_url,
                path: path,
                className: orientation.className
            };

            switch (item.orientation) {
                case 'Square':
                    images.square.push(image);
                    break;
                case 'Landscape':
                    images.landscape.push(image);
                    break;
                case 'Portrait':
                    images.portrait.push(image);
                    break;
            }
        });

        return images;
    }
})();